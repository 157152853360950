import React from "react"
import Faq from "react-faq-component"

import "./faq.css"

const data = {
  title: "Frequently Asked Questions",
  rows: [
    {
      title: "Apakah itu NFT? Keuntungan membeli NFT?",
      content: (
        <div>
          <p>
            Non-Fungible Token (NFT) adalah aset digital yang merepresentasikan
            sebuah karya yang unik dan tidak dapat diduplikasi dan ditukarkan
            seperti aset lainnya. Untuk dapat mengerti NFT, dapat terlebih
            dahulu memahami makna Non-Fungible.
          </p>
          <p>
            Non-Fungible merupakan antonim dari kata Fungible yaitu kemampuan
            suatu aset untuk dapat diperdagangkan atau ditukar satu sama lain.
            Contohnya aset yang fungible adalah mata uang fisik atau kripto,
            artinya mereka dapat diperdagangkan atau ditukar satu sama lain
            dengan nilai yang sama. Uang Rp 50.000 selalu bernilai sama jika
            terdiri dari satu lembar Rp 50.000 ataupun lima lembar Rp 10.000.
            Satu Bitcoin pun selalu sama dengan Bitcoin lainnya.
          </p>
          <p>
            Sementara, aset non-fungible adalah unik dan tidak dapat ditukarkan
            satu sama lain. Contoh, jika Anda menukarkan lukisan Mona Lisa
            dengan lukisan lain, Anda akan mendapat karya yang pasti berbeda.
            Setiap NFT menyimpan data pencipta, histori, harga, dan kepemilikan
            di dalam jaringan blockchain setiap kali transaksi terjadi.
          </p>
          <p>
            Dengan memiliki NFT, anda memiliki hak atas media digital, seperti
            karya seni, foto, musik, dan lain-lain yang menjadikannya barang
            mewah versi digital. NFT pun menjadi token utilitas jika dibeli
            melalui TiketNFT karena memberi berbagai keuntungan berwujud nyata
            serta sebagai bentuk dukungan terhadap seniman, tim olahraga, dan
            berbagai tokoh favoritmu yang lain.
          </p>
        </div>
      ),
    },
    {
      title: "Bagaimana cara membeli NFT di TiketNFT? ",
      content: (
        <div>
          <p>Anda dapat membeli Karya Digital (NFT) dengan dua cara yaitu:</p>
          <p>
            i. Dengan menjadi pembeli pertama NFT dari kami melalui metode
            Minting;
          </p>
          <p>ii. Dengan membeli NFT dari pengguna lain di Marketplace</p>
          <p>
            Minting adalah proses pendaftaran karya digital pencipta menjadi
            sebuah NFT. Token NFT ini kemudian akan disimpan di blockchain.
            Setelah menjadi sebuah NFT, karya tersebut tidak bisa dimodifikasi.
            Untuk melakukan pembelian atau kontrak di jaringan blockchain,
            umumnya terdapat pembebanan biaya untuk melakukan transaksi yang
            disebut sebagai Gas Fee.
          </p>
          <p>
            Dengan menggunakan TiketNFT, Anda dimudahkan untuk melakukan
            transaksi tersebut yaitu cukup dengan mengikuti instruksi pada
            aplikasi. Setelah minting, Anda dapat menjadi pembeli pertama dan
            mengumpulkan atau menjual NFT anda di Marketplace. Selain itu, Anda
            pun dapat membeli NFT milik pengguna lain dari Marketplace tersebut.
          </p>
        </div>
      ),
    },
    {
      title: "Apakah saya dapat menjual NFT di TiketNFT?",
      content: (
        <div>
          <p>
            Belum, untuk sekarang Anda masih belum bisa melakukan penjualan NFT
            koleksi Anda. Penjualan NFT dapat dilakukan pada Aplikasi TiketNFT
            yang akan direlease dalam versi selanjutnya
          </p>
        </div>
      ),
    },
    {
      title: "Bagaimana mekanisme transaksi yang ada pada TiketNFT?",
      content: (
        <div>
          <p>
            Dalam satu kali proses transaksi, Anda dapat membeli banyak NFT
            untuk jenis tiket yang sama. Anda juga dapat mengecek status
            transaksi Anda melalui fitur “Riwayat Transaksi” yang ada pada
            halaman “Akun” Anda. NFT yang sudah berhasil dibeli, dapat Anda
            lihat melalui halaman “Koleksi” yang ada pada website TiketNFT.
          </p>
        </div>
      ),
    },
    {
      title:
        "Apakah saya dapat mengubah alamat email yang saya gunakan pada akun TiketNFT?",
      content: (
        <div>
          <p>
            Tidak. Alamat email Anda adalah identitas yang terdaftar secara unik
            di database TiketNFT, dimana satu alamat email hanya dapat digunakan
            untuk mendaftarkan satu buah akun TiketNFT. Namun, informasi lain
            tentang diri Anda dapat diubah melalui halaman “Akun” pada website
            TiketNFT.
          </p>
        </div>
      ),
    },
    {
      title:
        "Bagaimana cara saya mengadukan masalah saya kepada pihak TiketNFT? ",
      content: (
        <div>
          <p>
            Anda dapat mengadukan masalah dan pertanyaan melalui form bantuan
            yang dapat diakses melalui Ajukan Bantuan Lain. Anda juga dapat
            menghubungi kami melalui platform media sosial TiketNFT maupun nomor
            telepon yang terdaftar pada website TiketNFT.
          </p>
        </div>
      ),
    },
    {
      title:
        "[IIMS] Penawaran apa saja yang disediakan oleh TiketNFT untuk IIMS 2022? ",
      content: (
        <div>
          <p>
            Melalui platform beta.tiketnft.com, kami menjual NFT IIMS 2022 yang tersedia dalam beberapa kelas, yaitu Raffle, Common, Rare, dan Super Rare. Penjualan NFT raffle dilakukan pada 21 Maret - 28 Maret 2022 dan penjualan NFT normal dilakukan pada 30 Maret - 10 April 2022.
          </p>
        </div>
      ),
    },
    {
      title: "[IIMS] Apa itu NFT Raffle? ",
      content: (
        <div>
          <p>
            NFT Raffle merupakan NFT yang dijual dengan sistem raffle, yang artinya akan dilakukan pengundian pada jenis NFT yang Anda dapat. Anda hanya perlu membayar dengan harga terendah (dibandingkan jenis NFT lain), namun Anda berkesempatan untuk mendapatkan salah satu dari NFT common, rare, maupun super rare untuk IIMS 2022. Penjualan NFT raffle akan dibuka pada tanggal 21 Maret 2022, sebelum penjualan NFT normal dilakukan.
          </p>
        </div>
      ),
    },
    {
      title:
        "[IIMS] Mengapa saya harus beli NFT IIMS melalui TiketNFT? ",
      content: (
        <div>
          <p>
            Dengan membeli NFT IIMS 2022 melalui TiketNFT berarti kamu telah menjadi bagian dari IIMS NFT Society dan tentunya akan mendapatkan berbagai benefit, spesial hanya dari TiketNFT.
          </p>
        </div>
      ),
    },
    {
      title:
        "[IIMS] Apa saja benefit yang saya dapatkan dengan membeli NFT IIMS melalui TiketNFT? ",
      content: (
        <div>
          <p>
            NFT IIMS 2022 yang dibeli melalui platform TiketNFT dapat digunakan sebagai akses masuk ke acara offline IIMS 2022 di JIExpo dan juga dilengkapi dengan berbagai diskon dan keuntungan sesuai kelas NFT yang Anda miliki. Selain itu, kamu juga akan mendapatkan unique NFT artwork resmi dari IIMS 2022.
          </p>
        </div>
      ),
    },
    {
      title:
        "[IIMS] Apakah NFT artwork yang saya dapatkan bisa menjadi akses masuk ke IIMS 2022? ",
      content: (
        <div>
          <p>
            NFT artwork yang Anda dapatkan setelah pembelian akan muncul di halaman “Koleksi” pada akun TiketNFT Anda. Sedangkan, untuk akses masuk dan penukaran benefit lainnya, dapat diklaim melalui e-voucher yang akan dikirimkan melalui Whatsapp, email terdaftar, ataupun pada halaman pengunduhan e-voucher, setelah pembayaran sukses.
          </p>
        </div>
      ),
    },
  ],
}

const styles = {
  // bgColor: 'white',
  // rowContentColor: 'grey',
  // arrowColor: "red",
}

const config = {
  // animate: true,
  // arrowIcon: "V",
  // tabFocus: true
}

export const FAQ = () => {
  return (
    <div className="container faq-root py-6">
      <div>
        <Faq data={data} styles={styles} config={config} />
      </div>
    </div>
  )
}
