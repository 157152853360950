import React from "react"
import SEO from "@/components/seo"
import { Footer } from "@/components/footer"
import { Nav } from "@/components/nav"
import { FAQ } from "@/components/faq"

const Home: React.FC = () => {
  return (
    <>
      <SEO title="Frequently Asked Questions" />
      <Nav />
      <FAQ />
      <Footer />
    </>
  )
}

export default Home
